import React from 'react';
import DepartmentList from '../components/admin/DepartmentList';

function DepartmentManagementPage() {
  return (
    <div>
      <DepartmentList />
    </div>
  );
}

export default DepartmentManagementPage; 