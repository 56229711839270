import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useOrganization } from '../../contexts/OrganizationContext';
import { OrganizationChart } from 'primereact/organizationchart';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { ConfirmDialog } from 'primereact/confirmdialog';
import UserList from './UserList';
import InviteUser from './InviteUser';

function DepartmentList() {
  const [departments, setDepartments] = useState([]);
  const [selectedNode, setSelectedNode] = useState(null);
  const [expandedKeys, setExpandedKeys] = useState({});
  const [showAddDialog, setShowAddDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [departmentToDelete, setDepartmentToDelete] = useState(null);
  const [newDeptName, setNewDeptName] = useState('');
  const [selectedParentId, setSelectedParentId] = useState(null);
  const { orgId, loading } = useOrganization();

  const fetchDepartments = async () => {
    if (!orgId) return;
    try {
      const response = await axios.get(`/api/organizations/${orgId}/departments`);
      setDepartments(response.data.departments);
    } catch (err) {
      console.error('Error fetching departments:', err);
    }
  };

  useEffect(() => {
    fetchDepartments();
  }, [orgId, loading]);

  const handleAddDepartment = async () => {
    try {
      await axios.post(`/api/organizations/${orgId}/departments`, {
        name: newDeptName,
        parent_department_id: selectedParentId
      });
      setNewDeptName('');
      setShowAddDialog(false);
      fetchDepartments();
    } catch (err) {
      console.error('Error creating department:', err);
    }
  };

  const handleDeleteDepartment = async () => {
    if (!departmentToDelete) return;
    
    try {
      await axios.delete(`/api/organizations/${orgId}/departments/${departmentToDelete.key}`);
      setDepartmentToDelete(null);
      fetchDepartments();
    } catch (err) {
      console.error('Error deleting department:', err);
      // You might want to show an error message to the user here
    }
  };

  const convertToOrgChart = (deptList) => {
    if (!deptList || deptList.length === 0) return [];

    // Create a root node to contain all top-level departments
    return [{
      key: null,
      type: 'department',
      label: 'Organization',
      className: 'department-node root-node',
      expanded: true,
      data: { id: null, name: 'Organization' },
      children: deptList.map(dept => ({
        key: dept.id,
        type: 'department',
        label: dept.name,
        className: 'department-node',
        expanded: expandedKeys[dept.id] || false,
        data: { id: dept.id, name: dept.name },
        children: dept.sub_departments && dept.sub_departments.length > 0
          ? dept.sub_departments.map(subDept => ({
            key: subDept.id,
            type: 'department',
            label: subDept.name,
            className: 'department-node',
            expanded: expandedKeys[subDept.id] || false,
            data: { id: subDept.id, name: subDept.name },
            children: subDept.sub_departments || []
          }))
          : []
      }))
    }];
  };

  const toggleNode = (node) => {
    setExpandedKeys(prev => ({
      ...prev,
      [node.key]: !prev[node.key]
    }));
  };

  const nodeTemplate = (node) => {
    return (
      <div 
        className="p-4 border rounded-lg bg-white shadow-sm cursor-pointer"
        onClick={() => toggleNode(node)}
      >
        <div className="flex items-center justify-between mb-2">
          <span className="text-lg font-semibold">{node.label}</span>
          <div className="flex gap-2">
            <Button 
              icon="pi pi-plus" 
              className="p-button-rounded p-button-text"
              onClick={(e) => {
                e.stopPropagation();
                setSelectedParentId(node.key);
                setShowAddDialog(true);
              }}
              tooltip="Add Sub-department"
            />
            <Button 
              icon="pi pi-trash" 
              className="p-button-rounded p-button-text p-button-danger"
              onClick={(e) => {
                e.stopPropagation();
                setDepartmentToDelete(node);
                setShowDeleteDialog(true);
              }}
              tooltip="Delete Department"
            />
          </div>
        </div>
        {selectedNode?.key === node.key && (
          <div className="mt-2" onClick={e => e.stopPropagation()}>
            <UserList departmentId={node.data.id} />
            <div className="mt-4">
              <InviteUser departmentId={node.data.id} />
            </div>
          </div>
        )}
      </div>
    );
  };

  const chartData = convertToOrgChart(departments);

  return (
    <div className="mt-8">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-semibold">Department Structure</h2>
        {/* <Button 
          label="Add Top-Level Department" 
          icon="pi pi-plus"
          onClick={() => {
            setSelectedParentId(null);
            setShowAddDialog(true);
          }}
        /> */}
      </div>
      
      {departments.length === 0 ? (
        <p className="text-gray-600">No departments available.</p>
      ) : (
        <div className="org-chart-container">
          <OrganizationChart
            value={chartData}
            nodeTemplate={nodeTemplate}
            selection={selectedNode}
            selectionMode="single"
            onSelectionChange={e => setSelectedNode(e.data)}
            className="w-full"
          />
        </div>
      )}

      <Dialog
        header="Add New Department"
        visible={showAddDialog}
        onHide={() => {
          setShowAddDialog(false);
          setNewDeptName('');
        }}
        footer={
          <div>
            <Button 
              label="Cancel" 
              icon="pi pi-times" 
              onClick={() => setShowAddDialog(false)} 
              className="p-button-text mr-2" 
            />
            <Button 
              label="Add" 
              icon="pi pi-check" 
              onClick={handleAddDepartment} 
              autoFocus 
            />
          </div>
        }
      >
        <div className="p-fluid">
          <div className="p-field">
            <label htmlFor="name" className="block mb-2">Department Name</label>
            <InputText
              id="name"
              value={newDeptName}
              onChange={(e) => setNewDeptName(e.target.value)}
              className="p-inputtext-sm w-full border border-gray-300 rounded-md p-2"
              autoFocus
            />
          </div>
        </div>
      </Dialog>

      <ConfirmDialog
        visible={showDeleteDialog}
        onHide={() => setShowDeleteDialog(false)}
        message={`Are you sure you want to delete the department "${departmentToDelete?.label}"? This action cannot be undone.`}
        header="Confirm Delete"
        icon="pi pi-exclamation-triangle"
        accept={handleDeleteDepartment}
        reject={() => setShowDeleteDialog(false)}
        acceptClassName="p-button-danger"
        rejectClassName="p-button-text mr-2"
      />

      <style jsx>{`
        .org-chart-container {
          overflow-x: auto;
          padding: 2rem;
        }
        :global(.department-node) {
          min-width: 200px;
        }
        :global(.p-organizationchart .p-organizationchart-line-down) {
          background: #64748b;
        }
        :global(.p-organizationchart .p-organizationchart-line-left) {
          border-right: 2px solid #64748b;
        }
        :global(.p-organizationchart .p-organizationchart-line-top) {
          border-top: 2px solid #64748b;
        }
        :global(.p-organizationchart .p-organizationchart-node-content) {
          border: none;
          background: none;
          padding: 0;
        }
        .p-organizationchart .p-organizationchart-node-content {
          background: none;
        }
        :global(.p-inputtext:enabled:focus) {
          border-color: #6366f1;
          box-shadow: 0 0 0 2px #c7d2fe;
        }
        :global(.p-dialog .p-dialog-footer button) {
          margin-right: 0.5rem;
        }
          .p-organizationchart .p-organizationchart-node-content .p-node-toggler i {
          display: flex;
          justify-content: center;
          }
        :global(.root-node) {
          visibility: hidden;
        }
        :global(.root-node .p-organizationchart-node-content) {
          display: none;
        }
      `}</style>
    </div>
  );
}

export default DepartmentList;