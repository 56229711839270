import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import axios from 'axios';
import Layout from './components/common/Layout';
import HomePage from './pages/HomePage';
import DashboardPage from './pages/DashboardPage';
import ProfilePage from './pages/ProfilePage';
import DocumentsPage from './pages/DocumentsPage';
import ConnectionsPage from './pages/ConnectionsPage';
import CalendarPage from './pages/CalendarPage';
import ChatPage from './pages/ChatPage';
import Login from './components/auth/Login';
import Register from './components/auth/Register';
import ForgotPassword from './components/auth/ForgotPassword';
import LifeROI from './pages/LifeROI';
import UserManagementPage from './pages/UserManagementPage';
import DepartmentManagementPage from './pages/DepartmentManagementPage';
import { OrganizationProvider } from './contexts/OrganizationContext';
import { UserProvider } from './contexts/UserContext';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { ConfirmDialog } from 'primereact/confirmdialog';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isOrgManager, setIsOrgManager] = useState(false);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const response = await axios.get('/api/check-auth');
        setIsAuthenticated(response.data.authenticated);
        if (response.data.authenticated) {
          const orgManagerResponse = await axios.get('/api/user/is-org-manager');
          setIsOrgManager(orgManagerResponse.data.isOrgManager);
        }
      } catch (error) {
        console.error('Error checking authentication:', error);
      } finally {
        setIsLoading(false);
      }
    };

    checkAuth();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <UserProvider>
      <OrganizationProvider>
        <Router>
          <Layout>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route
                path="/dashboard"
                element={isAuthenticated ? <DashboardPage /> : <Navigate to="/login" />}
              />
              <Route
                path="/profile"
                element={isAuthenticated ? <ProfilePage /> : <Navigate to="/login" />}
              />
              <Route
                path="/documents"
                element={isAuthenticated ? <DocumentsPage /> : <Navigate to="/login" />}
              />
              <Route
                path="/documents/upload"
                element={isAuthenticated ? <DocumentsPage /> : <Navigate to="/login" />}
              />
              <Route
                path="/documents/organize"
                element={isAuthenticated ? <DocumentsPage /> : <Navigate to="/login" />}
              />
              <Route
                path="/connections"
                element={isAuthenticated ? <ConnectionsPage /> : <Navigate to="/login" />}
              />
              <Route
                path="/calendar"
                element={isAuthenticated ? <CalendarPage /> : <Navigate to="/login" />}
              />
              <Route
                path="/chat"
                element={isAuthenticated ? <ChatPage /> : <Navigate to="/login" />}
              />
              <Route
                path="/chat/presets"
                element={isAuthenticated ? <ChatPage /> : <Navigate to="/login" />}
              />
              <Route
                path="/chat/projects"
                element={isAuthenticated ? <ChatPage /> : <Navigate to="/login" />}
              />
              <Route
                path="/chat/previous"
                element={isAuthenticated ? <ChatPage /> : <Navigate to="/login" />}
              />
              <Route
                path="/life-roi"
                element={isAuthenticated ? <LifeROI /> : <Navigate to="/login" />}
              />
              {isOrgManager && (
                <>
                  <Route
                    path="/admin/users"
                    element={<UserManagementPage />}
                  />
                  <Route
                    path="/admin/departments"
                    element={<DepartmentManagementPage />}
                  />
                </>
              )}
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </Layout>
          <ConfirmDialog />
        </Router>
      </OrganizationProvider>
    </UserProvider>
  );
}

export default App;