import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SideNav from './SideNav';
import { useUser } from '../../contexts/UserContext';

function Layout({ children }) {
  const { id: currentUserId } = useUser();
  const isLoggedIn = currentUserId != null;
  const [accountType, setAccountType] = useState('individual');
  const [organizations, setOrganizations] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [isOrgAdmin, setIsOrgAdmin] = useState(false);
  const [isNavCollapsed, setIsNavCollapsed] = useState(false);

  useEffect(() => {
    if (isLoggedIn) {
      fetchUserOrganizations();
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (selectedOrg) {
      checkOrgAdminStatus(selectedOrg.id);
    }
  }, [selectedOrg]);

  const fetchUserOrganizations = async () => {
    try {
      const response = await axios.get('/api/user/organizations');
      setOrganizations(response.data.organizations);
    } catch (error) {
      console.error('Error fetching organizations:', error);
    }
  };

  const checkOrgAdminStatus = async (orgId) => {
    try {
      const response = await axios.get(`/api/organizations/${orgId}/is-admin`);
      setIsOrgAdmin(response.data.isAdmin);
    } catch (error) {
      console.error('Error checking admin status:', error);
      setIsOrgAdmin(false);
    }
  };

  const handleAccountChange = (value) => {
    if (value === 'individual') {
      setAccountType('individual');
      setSelectedOrg(null);
      setIsOrgAdmin(false);
    } else {
      const org = organizations.find(org => org.id === value);
      setAccountType('organization');
      setSelectedOrg(org);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50">
      {isLoggedIn && (
        <div className={`fixed top-0 ${isNavCollapsed ? 'left-16' : 'left-64'} right-0 bg-white border-b border-gray-200 z-10`}>
          <div className="px-4 py-2">
            <select
              value={selectedOrg ? selectedOrg.id : 'individual'}
              onChange={(e) => handleAccountChange(e.target.value)}
              className="block w-48 px-3 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            >
              <option value="individual">Individual Account</option>
              {Array.isArray(organizations) && organizations.map(org => (
                <option key={org.id} value={org.id}>
                  {org.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      )}
      
      <SideNav 
        isCollapsed={isNavCollapsed}
        setIsCollapsed={setIsNavCollapsed}
        showLifeROI={accountType === 'individual'}
        showDepartments={accountType === 'organization' && isOrgAdmin}
      />
      
      <main className={`${isNavCollapsed ? 'ml-16' : 'ml-64'} ${isLoggedIn ? 'pt-16' : ''} transition-all duration-300`}>
        <div className="px-6 py-8">
          {children}
        </div>
      </main>
    </div>
  );
}

export default Layout; 