import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useOrganization } from '../../contexts/OrganizationContext';

function UserList({ departmentId }) {
  const [users, setUsers] = useState([]);
  const { orgId, loading } = useOrganization();

  useEffect(() => {
    const fetchUsers = async () => {
      if (!orgId || !departmentId) return;
      
      try {
        const response = await axios.get(`/api/organizations/${orgId}/departments/${departmentId}/users`);
        setUsers(response.data.users);
      } catch (err) {
        console.error('Error fetching users for department:', err);
      }
    };

    fetchUsers();
  }, [orgId, loading, departmentId]);

  const handleRoleChange = async (userId, newRole) => {
    try {
      await axios.put(`/api/organizations/${orgId}/departments/${departmentId}/users/${userId}`, {
        role: newRole
      });
      // Refresh users after role change
      const response = await axios.get(`/api/organizations/${orgId}/departments/${departmentId}/users`);
      setUsers(response.data.users);
    } catch (err) {
      console.error('Error updating user role:', err);
    }
  };

  const handleRemoveUser = async (userId) => {
    try {
      await axios.delete(`/api/organizations/${orgId}/departments/${departmentId}/users/${userId}`);
      // Refresh users after removal
      const response = await axios.get(`/api/organizations/${orgId}/departments/${departmentId}/users`);
      setUsers(response.data.users);
    } catch (err) {
      console.error('Error removing user:', err);
    }
  };

  const handleResendInvite = async (userId) => {
    try {
      await axios.post(`/api/organizations/${orgId}/departments/${departmentId}/users/${userId}/resend-invite`);
      // Refresh users after resending invite
      const response = await axios.get(`/api/organizations/${orgId}/departments/${departmentId}/users`);
      setUsers(response.data.users);
    } catch (err) {
      console.error('Error resending invite:', err);
    }
  };

  return (
    <div className="ml-4 mt-2">
      <h3 className="text-lg font-medium mb-2">Users</h3>
      {users.length === 0 ? (
        <p className="text-gray-500">No users in this department.</p>
      ) : (
        <table className="min-w-full bg-white border">
          <thead>
            <tr>
              <th className="py-2 px-4 border-b">Name</th>
              <th className="py-2 px-4 border-b">Email</th>
              <th className="py-2 px-4 border-b">Role</th>
              <th className="py-2 px-4 border-b">Actions</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr key={user.id}>
                <td className="py-2 px-4 border-b">
                  {user.name || 'Pending'}
                  {user.registration_status === 'pending' && (
                    <span className="ml-2 px-2 py-1 text-xs bg-yellow-100 text-yellow-800 rounded-full">
                      Pending Registration
                    </span>
                  )}
                </td>
                <td className="py-2 px-4 border-b">{user.email}</td>
                <td className="py-2 px-4 border-b">
                  <select
                    value={user.role}
                    onChange={(e) => handleRoleChange(user.id, e.target.value)}
                    className="border rounded px-2 py-1"
                    disabled={user.registration_status === 'pending'}
                  >
                    <option value="employee">Employee</option>
                    <option value="manager">Manager</option>
                  </select>
                </td>
                <td className="py-2 px-4 border-b">
                  <button
                    onClick={() => handleRemoveUser(user.id)}
                    className="text-red-500 hover:underline"
                  >
                    Remove
                  </button>
                  {user.registration_status === 'pending' && (
                    <button
                      onClick={() => handleResendInvite(user.id)}
                      className="ml-2 text-blue-500 hover:underline"
                    >
                      Resend Invite
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {/* Optionally, include InviteUser component to add users to this department */}
      {/* <InviteUser departmentId={departmentId} /> */}
    </div>
  );
}

export default UserList; 