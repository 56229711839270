import React from 'react';
import CalendarEvents from '../components/calendar/CalendarEvents';

function CalendarPage() {
  return (
    <div>
      <CalendarEvents />
    </div>
  );
}

export default CalendarPage;