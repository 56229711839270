import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useUser } from '../../contexts/UserContext';
import {
  HomeIcon,
  FolderIcon,
  ChatBubbleLeftRightIcon,
  Cog6ToothIcon,
  ChartBarIcon,
  BuildingOfficeIcon,
  ArrowLeftCircleIcon,
  ArrowRightCircleIcon,
} from '@heroicons/react/24/outline';

function SideNav({ isCollapsed, setIsCollapsed, showLifeROI, showDepartments }) {
  const location = useLocation();
  const { id: currentUserId } = useUser();
  const isLoggedIn = currentUserId != null;

  const isActive = (path) => location.pathname === path;

  const navItemClass = (path) =>
    `flex items-center p-2 rounded-lg ${
      isActive(path)
        ? 'bg-indigo-100 text-indigo-700'
        : 'text-gray-700 hover:bg-gray-100'
    }`;

  const iconClass = "w-6 h-6";

  const renderNavLink = (to, icon, text, isMainLevel = true) => (
    <Link
      to={to}
      className={`${navItemClass(to)} ${
        isMainLevel ? '' : 'ml-4 text-sm'
      } transition-all duration-300`}
    >
      {icon}
      {!isCollapsed && <span className="ml-2">{text}</span>}
    </Link>
  );

  return (
    <nav 
      className={`${
        isCollapsed ? 'w-16' : 'w-64'
      } h-screen bg-white border-r border-gray-200 fixed left-0 top-0 overflow-y-auto transition-all duration-300`}
    >
      <div className="p-4">
        <div className="flex justify-between items-center mb-8">
          {!isCollapsed && (
            <Link to="/" className="text-2xl font-bold text-indigo-600">
              HiAI
            </Link>
          )}
          <button
            onClick={() => setIsCollapsed(!isCollapsed)}
            className="p-1 rounded-full hover:bg-gray-100"
          >
            {isCollapsed ? (
              <ArrowRightCircleIcon className={iconClass} />
            ) : (
              <ArrowLeftCircleIcon className={iconClass} />
            )}
          </button>
        </div>

        {isLoggedIn && (
          <div className="space-y-2">
            {renderNavLink('/dashboard', <HomeIcon className={iconClass} />, 'My Dashboard')}

            <div className="space-y-1">
              {renderNavLink('/documents', <FolderIcon className={iconClass} />, 'Data Portal')}
              {!isCollapsed && (
                <>
                  {renderNavLink('/documents/upload', null, 'Upload', false)}
                  {renderNavLink('/documents/organize', null, 'Organize', false)}
                </>
              )}
            </div>

            <div className="space-y-1">
              {renderNavLink('/chat', <ChatBubbleLeftRightIcon className={iconClass} />, 'How can HiAI help you?')}
              {!isCollapsed && (
                <>
                  {renderNavLink('/chat/presets', null, 'Manage pre-set messages', false)}
                  {renderNavLink('/chat/projects', null, 'Projects', false)}
                  {renderNavLink('/chat/previous', null, 'Previous', false)}
                </>
              )}
            </div>

            {renderNavLink('/settings', <Cog6ToothIcon className={iconClass} />, 'Settings')}

            {showLifeROI && renderNavLink('/life-roi', <ChartBarIcon className={iconClass} />, 'Life ROI')}

            {showDepartments && renderNavLink('/admin/departments', <BuildingOfficeIcon className={iconClass} />, 'Department Management')}
          </div>
        )}

        {!isLoggedIn && (
          <div className="space-y-2">
            <Link to="/login" className={navItemClass('/login')}>
              Sign In
            </Link>
            <Link to="/register" className={navItemClass('/register')}>
              Sign Up
            </Link>
          </div>
        )}
      </div>
    </nav>
  );
}

export default SideNav; 