import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useOrganization } from '../../contexts/OrganizationContext';

function InviteUser({ departmentId }) {
  const [email, setEmail] = useState('');
  const [selectedDepartment, setSelectedDepartment] = useState(departmentId || '');
  const [role, setRole] = useState('employee');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const { orgId, loading } = useOrganization();

  const handleInvite = async (e) => {
    e.preventDefault();
    setMessage('');
    setError('');

    try {
      const response = await axios.post(`/api/organizations/${orgId}/invite`, {
        email,
        department_id: selectedDepartment || null,
        role,
      });
      setMessage(response.data.message);
      setEmail('');
      setSelectedDepartment(departmentId || '');
      setRole('employee');
    } catch (err) {
      console.error('Error inviting user:', err);
      setError(err.response?.data?.error || 'Failed to invite user.');
    }
  };

  return (
    <div className="mb-8">
      <h2 className="text-2xl font-semibold mb-4">Invite New User</h2>
      <form onSubmit={handleInvite} className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">Email Address</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
            placeholder="user@example.com"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Role</label>
          <select
            value={role}
            onChange={(e) => setRole(e.target.value)}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
          >
            <option value="employee">Employee</option>
            <option value="manager">Manager</option>
          </select>
        </div>
        <button
          type="submit"
          className="bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-indigo-700"
        >
          Invite User
        </button>
        {message && <p className="text-green-500">{message}</p>}
        {error && <p className="text-red-500">{error}</p>}
      </form>
    </div>
  );
}

export default InviteUser; 