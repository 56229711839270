import React from 'react';
import UserList from '../components/admin/UserList';

function UserManagementPage() {
  return (
    <div>
      <h1 className="text-3xl font-bold mb-6">User Management</h1>
      <UserList />
    </div>
  );
}

export default UserManagementPage;