import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import Progress from '../common/Progress';

function FileUpload({ onUploadSuccess, setToast }) {
  const [files, setFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isAdmin, setIsAdmin] = useState(false);
  const [organizationUsers, setOrganizationUsers] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState('');
  const [isShared, setIsShared] = useState(false);
  const fileInputRef = useRef(null);

  useEffect(() => {
    // Check if user is org admin
    const checkAdminStatus = async () => {
      try {
        const response = await axios.get('/api/user/is-org-admin');
        setIsAdmin(response.data.isOrgAdmin);
        
        if (response.data.isOrgAdmin) {
          // Fetch organization users
          const usersResponse = await axios.get(`/api/organizations/${response.data.data.organization_id}/users`);
          setOrganizationUsers(usersResponse.data.users.map(user => ({
            id: user.user.id,
            name: user.user.name,
            email: user.user.email
          })));
        }
      } catch (error) {
        console.error('Error checking admin status:', error);
      }
    };
    
    checkAdminStatus();
  }, []);

  useEffect(() => {
    if (selectedUserId) {
      setIsShared(true);
    }
  }, [selectedUserId]);

  const handleFileChange = (e) => {
    setFiles(Array.from(e.target.files));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (files.length === 0) return;

    setIsUploading(true);
    const formData = new FormData();
    files.forEach((file) => formData.append('files', file));
    formData.append('is_shared', isShared);
    if (isAdmin && selectedUserId) {
      formData.append('target_user_id', selectedUserId);
    }

    try {
      const response = await axios.post('/api/upload', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setProgress(percentCompleted);
        },
      });

      setFiles([]);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
      if (onUploadSuccess) {
        onUploadSuccess();
      }
      setToast({ message: 'Files uploaded successfully', type: 'success' });
    } catch (error) {
      console.error('Error uploading files:', error);
      setToast({ message: 'Error uploading files', type: 'error' });
    } finally {
      setIsUploading(false);
      setProgress(0);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="mb-6">
      <div className="flex flex-col space-y-4 max-w-2xl">
        <div className="flex items-center justify-between">
          <input
            type="file"
            onChange={handleFileChange}
            multiple
            ref={fileInputRef}
            accept=".txt,.docx,.pdf,.xlsx,.pptx,.vtt"
            className="w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
          />
        </div>

        {isAdmin && (
          <div className="flex flex-col space-y-2">
            <label className="text-sm font-medium">Upload for User:</label>
            <select
              value={selectedUserId}
              onChange={(e) => setSelectedUserId(e.target.value)}
              className="p-2 border rounded"
            >
              <option value="">Upload for myself</option>
              {organizationUsers.map((user) => (
                <option key={user.id} value={user.id}>
                  {user.name} ({user.email})
                </option>
              ))}
            </select>
          </div>
        )}

        <div className="flex items-center">
          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              checked={isShared}
              onChange={(e) => setIsShared(e.target.checked)}
              disabled={!!selectedUserId}
              className="form-checkbox"
            />
            <span>Share with organization</span>
          </label>
        </div>

        <div className="flex justify-start">
          <button
            type="submit"
            disabled={files.length === 0 || isUploading}
            className="inline-flex px-6 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 disabled:bg-gray-400 transition-colors duration-200"
          >
            Upload Files
          </button>
        </div>
      </div>

      {isUploading && (
        <Progress
          message="Uploading and processing files..."
          progress={progress}
        />
      )}
    </form>
  );
}

export default FileUpload;