import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Toast from '../common/Toast';

function Profile() {
  const [user, setUser] = useState({
    name: '',
    username: '',
    email: '',
  });
  const [error, setError] = useState('');
  const [toast, setToast] = useState({ message: '', type: '' });
  const [usernameAvailable, setUsernameAvailable] = useState(true);
  const [checkingUsername, setCheckingUsername] = useState(false);

  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    try {
      const response = await axios.get('/api/user');
      setUser(response.data);
    } catch (error) {
      console.error('Error fetching user data:', error);
      setError('Failed to fetch user data');
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
    if (name === 'username') {
      checkUsernameAvailability(value);
    }
  };

  const checkUsernameAvailability = async (username) => {
    if (username.length >= 6) {
      setCheckingUsername(true);
      if (username === user.username) {
        setUsernameAvailable(true);
      } else {
        try {
          const response = await axios.get(`/api/check-username/${username}`);
          setUsernameAvailable(response.data.available);
        } catch (err) {
          console.error('Error checking username:', err);
          setUsernameAvailable(false);
        }
      }
      setCheckingUsername(false);
    } else {
      setUsernameAvailable(true);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post('/api/user', user);
      setToast({ message: 'Profile updated successfully', type: 'success' });
    } catch (error) {
      console.error('Error updating user data:', error);
      setToast({ message: 'Failed to update profile', type: 'error' });
    }
  };

  return (
    <div>
      <h2 className="text-2xl font-bold mb-4">Profile</h2>
      {error && <p className="text-red-500 mb-4">{error}</p>}
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label htmlFor="name" className="block text-sm font-medium text-gray-700">Name</label>
          <input
            type="text"
            name="name"
            id="name"
            value={user.name || ''}
            onChange={handleChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
          />
        </div>
        <div>
          <label htmlFor="username" className="block text-sm font-medium text-gray-700">Username</label>
          <input
            type="text"
            name="username"
            id="username"
            value={user.username || ''}
            onChange={handleChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
          />
        </div>
        <div>
          <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
          <input
            type="email"
            name="email"
            id="email"
            value={user.email || ''}
            onChange={handleChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
          />
        </div>
        <button
          type="submit"
          className={`bg-indigo-600 text-white px-4 py-2 rounded-md ${usernameAvailable ? 'hover:bg-indigo-700' : 'opacity-50 cursor-not-allowed'
            }`}
          disabled={!usernameAvailable}
        >
          Save Changes
        </button>
      </form>
      {toast && <Toast message={toast.message} type={toast.type} onClose={() => setToast(null)} />}
    </div>
  );
}

export default Profile;