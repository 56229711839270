import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SyncCalendarForm from './SyncCalendarForm';
import Toast from '../common/Toast';
import Progress from '../common/Progress';
import Modal from '../common/Modal';

function CalendarEvents() {
  const [events, setEvents] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [toast, setToast] = useState({ show: false, message: '', type: '' });
  const [progress, setProgress] = useState({ show: false, message: '', value: 0 });
  const [modal, setModal] = useState({ 
    isOpen: false, 
    title: '', 
    content: '', 
    onConfirm: null,
    isConfirmation: false 
  });

  useEffect(() => {
    fetchEvents();
  }, [searchQuery]);

  const fetchEvents = async () => {
    try {
      const response = await axios.get(`/api/calendar/events?search=${searchQuery}`);
      setEvents(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching events:', error);
      setError('Failed to fetch events');
      setLoading(false);
    }
  };

  const showToast = (message, type = 'success') => {
    setToast({ show: true, message, type });
  };

  const showConfirmModal = (title, content, onConfirm) => {
    setModal({ 
      isOpen: true, 
      title, 
      content, 
      onConfirm,
      isConfirmation: true 
    });
  };

  const handleConfirm = () => {
    if (modal.onConfirm) {
      modal.onConfirm();
    }
    closeModal();
  };

  const handleDeleteAll = async () => {
    showConfirmModal(
      'Delete All Events',
      'Are you sure you want to delete all events? This action cannot be undone.',
      async () => {
        setProgress({ show: true, message: 'Deleting all events...', value: 0 });
        try {
          const response = await axios.delete('/api/calendar/events/all');
          setProgress({ show: true, message: 'Deleting all events...', value: 100 });
          setTimeout(() => setProgress({ show: false, message: '', value: 0 }), 500);
          setEvents({});
          showToast(`Successfully deleted ${response.data.count} events`);
        } catch (error) {
          showToast('Failed to delete all events', 'error');
        }
      }
    );
  };

  const handleDeleteTag = async (tag) => {
    showConfirmModal(
      'Delete Tag Events',
      `Are you sure you want to delete all events with tag "${tag}"? This action cannot be undone.`,
      async () => {
        setProgress({ show: true, message: `Deleting events with tag "${tag}"...`, value: 0 });
        try {
          const response = await axios.delete(`/api/calendar/events/tag/${tag}`);
          setProgress({ show: true, message: `Deleting events with tag "${tag}"...`, value: 100 });
          setTimeout(() => setProgress({ show: false, message: '', value: 0 }), 500);
          const updatedEvents = { ...events };
          delete updatedEvents[tag];
          setEvents(updatedEvents);
          showToast(`Successfully deleted ${response.data.count} events with tag "${tag}"`);
        } catch (error) {
          showToast(`Failed to delete events with tag "${tag}"`, 'error');
        }
      }
    );
  };

  const handleSync = async (syncOptions) => {
    setProgress({ show: true, message: 'Starting calendar sync...', value: 0 });
    try {
      const response = await axios.post('/api/calendar/sync', syncOptions);
      
      // Show final success message
      setProgress({ 
        show: true, 
        message: `Successfully synced ${response.data.events_count} events`, 
        value: 100 
      });
      
      // Hide progress after a delay
      setTimeout(() => setProgress({ show: false, message: '', value: 0 }), 2000);
      
      // Refresh events list
      fetchEvents();
      
      showToast(`Successfully synced ${response.data.events_count} events`);
    } catch (error) {
      setProgress({ show: false, message: '', value: 0 });
      showToast('Failed to sync calendar: ' + (error.response?.data?.error || error.message), 'error');
    }
  };

  const handleDelete = async (eventId) => {
    try {
      await axios.delete(`/api/calendar/events/${eventId}`);
      
      // Update local state
      const updatedEvents = {};
      Object.keys(events).forEach(tag => {
        updatedEvents[tag] = events[tag].filter(event => event.id !== eventId);
        if (updatedEvents[tag].length === 0) {
          delete updatedEvents[tag];
        }
      });
      setEvents(updatedEvents);
      
      showToast('Event deleted successfully');
    } catch (error) {
      showToast('Failed to delete event: ' + (error.response?.data?.error || error.message), 'error');
    }
  };

  const showModal = (title, content) => {
    setModal({ isOpen: true, title, content });
  };

  const closeModal = () => {
    setModal({ isOpen: false, title: '', content: '' });
  };

  return (
    <div>
      <h1 className="text-3xl font-bold mb-8">Calendar Events</h1>
      <SyncCalendarForm onSync={handleSync} />
      <input
        type="text"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        placeholder="Search events..."
        className="w-full p-2 mb-4 border rounded"
        hidden={Object.keys(events).length === 0}
      />
      <div className="mb-4" hidden={Object.keys(events).length === 0}>
        <button
          onClick={handleDeleteAll}
          className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 mr-2"
        >
          Delete All Events
        </button>
      </div>
      {loading ? (
        <p>Loading events...</p>
      ) : error ? (
        <p className="text-red-500">{error}</p>
      ) : (
        <div>
          {Object.entries(events).map(([tag, tagEvents]) => (
            <div key={tag} className="mb-8">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-2xl font-semibold">{tag}</h2>
                <button
                  onClick={() => handleDeleteTag(tag)}
                  className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600"
                >
                  Delete Tag
                </button>
              </div>
              <ul className="space-y-4">
                {tagEvents.map(event => (
                  <li key={event.id} id={event.id} className="bg-white shadow rounded-lg p-4">
                    <h3 className="text-xl font-semibold">{event.summary}</h3>
                    <p className="text-gray-600">{new Date(event.start_time).toLocaleString()} - {new Date(event.end_time).toLocaleString()}</p>
                    {event.description && <p className="mt-2">{event.description}</p>}
                    <button
                      onClick={() => handleDelete(event.id)}
                      className="mt-2 bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600 transition duration-200"
                    >
                      Delete
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      )}
      {toast.show && (
        <Toast
          message={toast.message}
          type={toast.type}
          onClose={() => setToast({ ...toast, show: false })}
        />
      )}
      {progress.show && (
        <Progress
          message={progress.message}
          progress={progress.value}
        />
      )}
      {modal.isOpen && (
        <Modal
          isOpen={modal.isOpen}
          onClose={closeModal}
          title={modal.title}
        >
          <p>{modal.content}</p>
          <div className="mt-4 flex justify-end space-x-2">
            {modal.isConfirmation ? (
              <>
                <button
                  onClick={closeModal}
                  className="px-4 py-2 text-gray-600 hover:text-gray-800"
                >
                  Cancel
                </button>
                <button
                  onClick={handleConfirm}
                  className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
                >
                  Confirm
                </button>
              </>
            ) : (
              <button
                onClick={closeModal}
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
              >
                Close
              </button>
            )}
          </div>
        </Modal>
      )}
    </div>
  );
}

export default CalendarEvents;