import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';

const OrganizationContext = createContext();

export function OrganizationProvider({ children }) {
  const [organizationData, setOrganizationData] = useState({
    isMember: false,
    orgId: null,
    role: null,
    isManager: false,
    isAdmin: false,
    data: null,
    loading: true,
  });

  useEffect(() => {
    const fetchOrgStatus = async () => {
      try {
        const [orgStatus, orgManager, orgAdmin] = await Promise.all([
          axios.get('/api/user/organization-status'),
          axios.get('/api/user/is-org-manager'),
          axios.get('/api/user/is-org-admin')
        ]);

        setOrganizationData({
          isMember: orgStatus.data.isMember,
          orgId: orgStatus.data.data?.organization_id || null,
          role: orgStatus.data.data?.role || null,
          isManager: orgManager.data.isOrgManager,
          isAdmin: orgAdmin.data.isOrgAdmin,
          data: orgStatus.data.data,
          loading: false,
        });
      } catch (error) {
        console.error('Error fetching organization status:', error);
        setOrganizationData(prev => ({ ...prev, loading: false }));
      }
    };

    fetchOrgStatus();
  }, []);

  return (
    <OrganizationContext.Provider value={organizationData}>
      {children}
    </OrganizationContext.Provider>
  );
}

export function useOrganization() {
  const context = useContext(OrganizationContext);
  if (context === undefined) {
    throw new Error('useOrganization must be used within an OrganizationProvider');
  }
  return context;
} 