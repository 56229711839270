import React, { useState, useEffect } from 'react';
import axios from 'axios';
import FileUpload from './FileUpload';
import Toast from '../common/Toast';
import { useUser } from '../../contexts/UserContext';

function Documents() {
  const [personalDocuments, setPersonalDocuments] = useState([]);
  const [uploadedForMeDocuments, setUploadedForMeDocuments] = useState([]);
  const [uploadedForOthersDocuments, setUploadedForOthersDocuments] = useState([]);
  const [organizationMode, setOrganizationMode] = useState(false);
  const [error, setError] = useState('');
  const [toast, setToast] = useState({ message: '', type: '' });
  const [isOrgMember, setIsOrgMember] = useState(false);
  const { id: currentUserId, loading: userLoading } = useUser();

  useEffect(() => {
    if (!userLoading) {
      fetchDocuments();
      checkOrgMembership();
    }
  }, [userLoading]);

  const fetchDocuments = async () => {
    try {
      const response = await axios.get('/api/user/documents');
      // Split documents into personal, uploaded-for-me, and uploaded-for-others
      const personal = [];
      const uploadedForMe = [];
      const uploadedForOthers = [];

      response.data.documents.forEach(doc => {
        if (doc.user_id === currentUserId) {
          personal.push(doc);
        } else if (doc.uploaded_by_user_id === currentUserId) {
          uploadedForOthers.push(doc);
        }
        else {
          uploadedForMe.push(doc);
        }
      });

      setPersonalDocuments(personal);
      setUploadedForMeDocuments(uploadedForMe);
      setUploadedForOthersDocuments(uploadedForOthers);
    } catch (error) {
      console.error('Error fetching documents:', error);
      setError('Failed to fetch documents');
    }
  };

  const checkOrgMembership = async () => {
    try {
      const response = await axios.get('/api/user/organization-status');
      setIsOrgMember(response.data.isMember);
    } catch (error) {
      console.error('Error checking organization membership:', error);
    }
  };

  const handlePermissionChange = async (documentId, newPermission) => {
    try {
      await axios.patch(`/api/user/documents/${documentId}/permission`, {
        permission_type: newPermission
      });
      fetchDocuments();
      setToast({ message: 'Document permission updated successfully', type: 'success' });
    } catch (error) {
      console.error('Error updating document permission:', error);
      setToast({ message: 'Failed to update document permission', type: 'error' });
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`/api/user/documents/${id}`);
      fetchDocuments();
      setToast({ message: 'Document deleted successfully', type: 'success' });
    } catch (error) {
      console.error('Error deleting document:', error);
      setToast({ message: 'Failed to delete document', type: 'error' });
    }
  };

  const handleUploadSuccess = () => {
    fetchDocuments();
    setToast({ message: 'File uploaded successfully', type: 'success' });
  };

  const DocumentList = ({ documents, isUploadedForMe = false, isUploadedForOthers = false }) => (
    <ul className="bg-white shadow-md rounded-lg divide-y divide-gray-200">
      {documents.map((doc) => (
        <li key={doc.id} className="flex justify-between items-center p-4 hover:bg-gray-50">
          <div className="flex-grow">
            <span className="font-medium">{doc.file_name}</span>
            <span className="ml-2 text-sm text-gray-500">({doc.file_type})</span>
            {isUploadedForMe && (
              <span className="ml-2 text-xs text-gray-500">
                Uploaded by: {doc.uploaded_by_name || 'Administrator'}
              </span>
            )}
            {/* {isUploadedForOthers && (
              <span className="ml-2 text-xs text-gray-500">
                Uploaded for: {doc.user_name || 'User'}
              </span>
            )} */}
          </div>
          <div className="flex items-center space-x-4">
            {isOrgMember && (
              <select
                value={doc.permission_type}
                onChange={(e) => handlePermissionChange(doc.id, e.target.value)}
                disabled={isUploadedForMe}
                className={`border rounded px-2 py-1 ${isUploadedForMe
                    ? 'bg-gray-100 cursor-not-allowed'
                    : 'cursor-pointer'
                  }`}
                title={isUploadedForMe ? "You cannot modify sharing settings for documents uploaded for you" : ""}
              >
                <option value="private">Private</option>
                <option value="organization">Organization</option>
              </select>
            )}
            <button
              onClick={() => handleDelete(doc.id)}
              disabled={isUploadedForMe}
              title={isUploadedForMe ? "You cannot delete documents uploaded for you" : ""}
              className={`px-3 py-1 rounded transition duration-200 ${isUploadedForMe
                  ? 'bg-gray-400 cursor-not-allowed'
                  : 'bg-red-500 hover:bg-red-600 text-white'
                }`}
            >
              Delete
            </button>
          </div>
        </li>
      ))}
    </ul>
  );

  return (
    <div>
      <h1 className="text-3xl font-bold mb-8">Document Management</h1>

      <section className="mb-12">
        <h2 className="text-2xl font-semibold mb-4">Upload New Document</h2>
        <div className="bg-gray-100 p-6 rounded-lg">
          <FileUpload onUploadSuccess={handleUploadSuccess} setToast={setToast} />
        </div>
      </section>

      {uploadedForMeDocuments.length > 0 && (
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Documents Shared With Me</h2>
          <DocumentList documents={uploadedForMeDocuments} isUploadedForMe={true} />
        </section>
      )}

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">My Documents</h2>
        {error && <p className="text-red-500 mb-4">{error}</p>}
        {personalDocuments.length === 0 ? (
          <p className="text-gray-600">No documents uploaded yet.</p>
        ) : (
          <DocumentList documents={personalDocuments} />
        )}
      </section>

      {uploadedForOthersDocuments.length > 0 && (
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Documents Uploaded for Others</h2>
          <DocumentList
            documents={uploadedForOthersDocuments}
            isUploadedForOthers={true}
          />
        </section>
      )}
      {toast.message && <Toast message={toast.message} type={toast.type} onClose={() => setToast({ message: '', type: '' })} />}
    </div>
  );
}

export default Documents;