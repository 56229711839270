import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Toast from '../common/Toast';
import ConnectionItem from './ConnectionItem';
import SyncCalendarForm from '../calendar/SyncCalendarForm';

function Connections() {
  const [connections, setConnections] = useState({
    googleCalendar: false,
    twitter: false,
    facebook: false,
  });
  const [toast, setToast] = useState({ message: '', type: '', show: false });

  const showToast = (message, type) => {
    setToast({ message, type, show: true });
    setTimeout(() => setToast({ message: '', type: '', show: false }), 3000);
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const connectionStatus = urlParams.get('connection_status');
    if (connectionStatus === 'success') {
      showToast('Successfully connected to Google Calendar', 'success');
      fetchConnections();
    } else if (connectionStatus === 'error') {
      showToast('Failed to connect to Google Calendar', 'error');
    }
    else {
      fetchConnections();
    }
  }, []);

  const fetchConnections = async () => {
    try {
      const response = await axios.get('/api/user/connections');
      setConnections(response.data);
    } catch (error) {
      console.error('Error fetching connections:', error);
      showToast('Failed to fetch connections', 'error');
    }
  };

  const handleConnect = async (service) => {
    try {
      const response = await axios.get(`/api/connect/${service}`);
      window.location.href = response.data.authUrl;
    } catch (error) {
      console.error(`Error connecting to ${service}:`, error);
      showToast(`Failed to connect to ${service}`, 'error');
    }
  };
  const handleDisconnect = async (service) => {
    try {
      await axios.post(`/api/disconnect/${service}`);
      fetchConnections();
      showToast(`Successfully disconnected from ${service}`, 'success');
    } catch (error) {
      console.error(`Error disconnecting from ${service}:`, error);
      showToast(`Failed to disconnect from ${service}`, 'error');
    }
  };

  const handleSyncCalendar = async (filterHashtag, syncFutureEvents) => {
    try {
      await axios.post('/api/calendar/sync', { filter_hashtag: filterHashtag, sync_future_events: syncFutureEvents });
      showToast('Calendar synced successfully', 'success');
    } catch (error) {
      console.error('Error syncing calendar:', error);
      showToast('Failed to sync calendar', 'error');
    }
  };

  return (
    <div>
      <div>
        <h1 className="text-3xl font-bold mb-8">Account Connections</h1>
        <div className="space-y-6">
          <ConnectionItem
            name="Google Calendar"
            connected={connections.googleCalendar}
            onConnect={() => handleConnect('google-calendar')}
            onDisconnect={() => handleDisconnect('google-calendar')}
          />
          <ConnectionItem
            name="Twitter"
            connected={connections.twitter}
            onConnect={() => handleConnect('twitter')}
            onDisconnect={() => handleDisconnect('twitter')}
          />
          <ConnectionItem
            name="Facebook"
            connected={connections.facebook}
            onConnect={() => handleConnect('facebook')}
            onDisconnect={() => handleDisconnect('facebook')}
          />
        </div>
      </div>
      {toast.show && (
        <Toast
          message={toast.message}
          type={toast.type}
          onClose={() => setToast({ ...toast, show: false })}
        />
      )}
    </div>
  );
}

export default Connections;